import gql from 'graphql-tag';

// QUERIES

const ongoingPeriodsInAssociationQuery = gql`
  query ($associationId: ObjectId!) {
    periods: ongoingPeriodsInAssociation(associationId: $associationId) {
      fromPeriod
      toPeriod
      currentPeriod
    }
  }
`;

const statOrdersByAssociationQuery = gql`
  query ($associationId: ObjectId!, $fromDate: DateTime, $toDate: DateTime) {
    orders: statOrdersByAssociation(associationId: $associationId, fromDate: $fromDate, toDate: $toDate) {
      #periods {
        #period
        #amount
        #number
      #}
      totalNumber
      totalUsedOrders
      #totalAmount
    }
  }
`;

const statAssociationTopTeamsQuery = gql`
  query ($associationId: ObjectId!, $fromDate: DateTime, $toDate: DateTime) {
    teams: statAssociationTopTeams(associationId: $associationId, fromDate: $fromDate, toDate: $toDate) {
      teams {
        team {
          _id
          name
        }
        total
      }
    }
  }
`;

const statAssociationTopTeamMembersQuery = gql`
  query ($associationId: ObjectId!, $fromDate: DateTime, $toDate: DateTime) {
    teamMembers: statAssociationTopTeamMembers(associationId: $associationId, fromDate: $fromDate, toDate: $toDate) {
      teamMembers {
        teamMember {
          _id
          firstname
          lastname
        }
        total
      }
    }
  }
`;

const getAssociationsQuery = gql`
  {
    associations: associations {
      _id
      zoneId
      name
      slug
      showInShopListOnDomains
    }
  }
`;

/*const getAssociationsInCityQuery = gql`
  query ($cityId: ObjectId!) {
    associations: associationsInCity(cityId: $cityId) {
      _id
      cityId
      name
      phone
      billingAddress {
        streetAddress
        zipCode
        city
        state
      }
      note
      agreements {
        _id
        activeFrom
        activeTo
      }
      adminEmails
      adminInvitations {
        sentToEmail
        userIdActivated
      }
    }
  }
`;
*/

const getAssociationsInZoneQuery = gql`
  query ($zoneId: ObjectId!) {
    associations: associationsInZone(zoneId: $zoneId) {
      _id
      zoneId
      name
      phone
      billingAddress {
        streetAddress
        zipCode
        city
        state
      }
      note
      agreements {
        _id
        activeFrom
        activeTo
      }
      adminEmails
      adminInvitations {
        sentToEmail
        userIdActivated
      }
      showInShopListOnDomains
      shopShareImageURL
    }
  }
`;


const getAssociationQuery = gql`
  query ($associationId: ObjectId!) {
    association: association(associationId: $associationId) {
      _id
      zoneId
      name
      phone
      logoURL
      billingAddress {
        streetAddress
        zipCode
        city
        state
      }
      note
      agreements {
        _id
        activeFrom
        activeTo
      }
      adminEmails
    }
  }
`;

const getAssociationBySlug = gql`
  query ($slug: String!) {
    association: associationBySlug(slug: $slug) {
      _id
      name
      zoneId
    }
  }
`

const getAssociationPublicQuery = gql`
  query ($associationId: ObjectId!) {
    association: associationPublic(associationId: $associationId) {
      name
      logoURL
    }
  }
`;

// MUTATIONS

const addAssociationMutation = gql`
  mutation(
    $zoneId: ObjectId!,
    $name: String!,
    $phone: String,
    $note: String,
    $logoURL: String,
    $bStreetAddress: String
    $bZipCode: String
    $bCity: String
    $bState: String
    $adminEmails: [String!]
    $showInShopListOnDomains: [String!]
    $shopShareImageURL: String
    ) {
    createAssociation(
      zoneId: $zoneId,
      name: $name,
      phone: $phone,
      logoURL: $logoURL,
      note: $note,
      adminEmails: $adminEmails,
      billingAddress: {
        streetAddress: $bStreetAddress
        zipCode: $bZipCode
        city: $bCity
        state: $bState
      }
      showInShopListOnDomains: $showInShopListOnDomains
      shopShareImageURL: $shopShareImageURL
      ) {
        _id
      }
    }
`;

const updateAssociationMutation = gql`
  mutation(
    $_id: ObjectId!
    $name: String!
    $phone: String
    $note: String
    $logoURL: String
    $bStreetAddress: String
    $bZipCode: String
    $bCity: String
    $bState: String
    $adminEmails: [String!]
    $showInShopListOnDomains: [String!]
    $shopShareImageURL: String
    ) {
    updateAssociation(
      _id: $_id,
      name: $name,
      phone: $phone,
      note: $note,
      logoURL: $logoURL,
      adminEmails: $adminEmails,
      billingAddress: {
        streetAddress: $bStreetAddress
        zipCode: $bZipCode
        city: $bCity
        state: $bState
      },
      showInShopListOnDomains: $showInShopListOnDomains
      shopShareImageURL: $shopShareImageURL
      ) {
        _id
    }
  }
`;


const addAgreementToAssociationMutation = gql`
  mutation(
    $associationId: ObjectId!
    $activeFrom: Int!
    $activeTo: Int!
    ) {
      addAssociationAgreement(
        associationId: $associationId,
        activeFrom: $activeFrom,
        activeTo: $activeTo,
        ) {
        _id
      }
    }
`;

const updateAgreementInAssociationMutation = gql`
  mutation(
    $associationId: ObjectId!
    $_id: ObjectId!
    $activeFrom: Int!
    $activeTo: Int!
    ) {
      updateAssociationAgreement(
        associationId: $associationId,
        agreementId: $_id,
        activeFrom: $activeFrom,
        activeTo: $activeTo,
        ) {
          _id
      }
    }
`;

export {
  ongoingPeriodsInAssociationQuery,
  statOrdersByAssociationQuery,
  statAssociationTopTeamsQuery,
  statAssociationTopTeamMembersQuery,
  getAssociationQuery,
  getAssociationBySlug,
  getAssociationPublicQuery,
  getAssociationsQuery,
  //getAssociationsInCityQuery,
  getAssociationsInZoneQuery,
  addAssociationMutation,
  updateAssociationMutation,
  addAgreementToAssociationMutation,
  updateAgreementInAssociationMutation,
};
