import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { getBookletQuery, categoriesInBookletQuery } from '../../queries/queries'
import Booklet from './Booklet'
import { useNavigationContext } from '../../context/NavigationContext'
import { BookletFilter } from './BookletFilter'
import { Link, useLocation } from 'react-router-dom';
import { IoChevronBackSharp } from 'react-icons/io5';
import ReactToPrint from 'react-to-print'
import { ImFilePdf } from 'react-icons/im';

const { REACT_APP_IMAGE_BASE_URL } = process.env;

export default function BookletView({ match, isInPopup = false }) {
  const location = useLocation();
  const { data: bookletData } = useQuery(getBookletQuery, { variables: { _id: match.params.booklet }, fetchPolicy: 'network-only' });
  const { data: bookletCategories } = useQuery(categoriesInBookletQuery, { variables: { bookletId: match.params.booklet }, fetchPolicy: 'network-only' });
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);

  const validTo = new Date(bookletData?.booklet?.validTo).toLocaleDateString('sv-SE', { day: 'numeric', month: 'numeric' }) || '';
  const isPreview = location.pathname.substring(1, 7) === 'cities';

  const { setNavigationActivated } = useNavigationContext();
  useEffect(() => {
    if (isPreview) {
      setNavigationActivated(true);
    } else {
      setNavigationActivated(false);
    }
  }, []);

  const bookletsRef = useRef(null);

  const printStyles = `
    body {
      padding: 0 !important;
      margin: 0 !important;
    }
    button.pdf, .change-booklet {
      display: none !important;
    }
    .container {
      width: 100vw !important;
      margin: 0 !important;
    }
    .booklet-filter {
      display: none !important;
    }
    .category-section {
      break-inside: avoid;
      padding-top: 2rem !important; 
    }
    .category-section h2 {
      font-size: 1.6vw !important;
    }
    .filter-button-icon svg {
      width: 2.2vw !important;
      height: 2.2vw !important;
    }
    .filter-button-icon svg path {
      fill: #333 !important;
    }
    .booklet-company-card {
      width: 25% !important;
    }
    .booklets-header {
      padding: 2vw 0 1.5vw 0 !important;
    }
    .booklet-card img {
      max-height: 4vw !important;
      max-width: 5vw !important;
    }
    .booklet-logo {
      height: 6vw !important;
      width: 6vw !important;
    }
    h1 {
      font-size: 2.6vw !important;
    }
    .valid-to {
      font-size: 1.1vw !important;
    }
    .booklet-card h2 {
      font-size: 1.6vw !important;
    }
    .booklet-card h2 .font-icon {
      font-size: 1.3vw !important;
    }
    a, p, span {
      font-size: 0.9vw !important;
      line-height: 1.3 !important;
    }
    .arrow {
      display: none !important;
    }
    .booklet-company-card {
      width: 25% !important;
    }
    .booklet-card .left {
      max-width: 100px !important;
    }
    .booklet-card img {
      max-width: 60px !important;
      max-height: 60px !important;
    }
    .booklet-card h2 {
      font-size: 1.2rem !important;
    }
    .booklet-card a {
      max-width: 100px !important;
    }
    .booklet-card .top-row p {
      font-size: 12px !important;
    }
  `;

  const handleIsPrinting = () => {
    setIsPrinting(!isPrinting);
    return Promise.resolve();
  }

  return (
    <div className={`booklets-view ${isInPopup ? 'popup' : ''}`}>
      <div ref={bookletsRef} className='booklets container bg-white' >
        <div className={`booklets-header ${isInPopup ? 'popup' : ''}`}>
          <div className='booklet-bg left' style={{ backgroundColor: bookletData?.booklet.colorLeft }} />
          <div className='booklet-bg right' style={{ backgroundColor: bookletData?.booklet.colorRight }} />
          {!isInPopup && <Link className='change-booklet' to={isPreview ? '/cities' : '/vara-haften'}><IoChevronBackSharp /> Byt häfte</Link>}
          {bookletData &&
            <ReactToPrint
              trigger={() => <button className="pdf">
                <ImFilePdf />
                Skriv ut
              </button>}
              bodyClass="booklet-pdf"
              pageStyle={printStyles}
              content={() => bookletsRef.current}
              onBeforeGetContent={handleIsPrinting}
              onAfterPrint={handleIsPrinting}
            />
          }
          <div className='booklet-logo'>
            <img src={REACT_APP_IMAGE_BASE_URL + bookletData?.booklet.logoURL} alt={bookletData?.booklet.title} />
          </div>
          <h1 style={{ color: bookletData?.booklet.colorFont || '#fff' }}>{bookletData?.booklet.title}</h1>

          <p className='valid-to' style={{ color: bookletData?.booklet.colorFont || '#fff' }}>Giltigt t.o.m {validTo}</p>
        </div>

        <BookletFilter
          selectedBookletCategories={bookletCategories?.categoriesInBooklet || []}
          categoriesFilter={categoriesFilter}
          setCategoriesFilter={setCategoriesFilter}
        />

        <Booklet
          bookletData={bookletData}
          bookletCategories={bookletCategories}
          categoriesFilter={categoriesFilter}
          isPrinting={isPrinting}
        />
      </div>
    </div>
  )
}
