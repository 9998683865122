import { useQuery } from "@apollo/client";
import {
  getCompanyEmailRecordsQuery,
  getReviewEmailRecordsQuery,
} from "../../../queries/emailRecord";
import React, { useEffect, useState } from "react";
import { EmailRecord } from "types/generated.types";
import convertStatus from "../utils/convertStatus";

interface props {
  bookletId?: any;
  zoneId: any;
  companyId?: any;
  isReview: boolean;
}

export default function HistoryTab({
  bookletId,
  zoneId,
  companyId,
  isReview,
}: props) {
  const [selectedRecord, setSelectedRecord] = useState<EmailRecord | null>(
    null
  );

  const {
    data: emailRecordData,
    loading: isLoading,
    refetch: refetchReviewEmails,
  } = useQuery(
    isReview ? getReviewEmailRecordsQuery : getCompanyEmailRecordsQuery,
    {
      variables: {
        zoneId: zoneId,
        ...(isReview && { companyId: companyId, bookletId: bookletId }),
      },
      fetchPolicy: "network-only",
    }
  );

  const emailRecords: EmailRecord[] = emailRecordData?.emailRecords || [];

  useEffect(() => {
    const interval = setInterval(() => {
      refetchReviewEmails();
    }, 5000);

    return () => clearInterval(interval);
  }, [refetchReviewEmails]);

  return (
    <>
      <div className="section">
        <h4>
          {isReview ? "Skickade granskningar" : "Skickade meddelanden i zon"}
        </h4>
        <ul className="records">
          {emailRecords.length != 0 ? (
            isLoading ? (
              <p>Loading...</p>
            ) : (
              emailRecords
                .slice()
                .sort(
                  (a, b) =>
                    new Date(b.sent as string).getTime() -
                    new Date(a.sent as string).getTime()
                )
                .map((record, index) => {
                  return (
                    <li
                      className={`record `}
                      style={{
                        backgroundColor: `${convertStatusToColor(
                          record.status
                        )}`,
                      }}
                      key={index}
                      onClick={() => setSelectedRecord(record)}
                    >
                      <p>
                        {record.company.name} - {record.booklet.title}
                      </p>
                      <p>Till: {record.to}</p>
                      <p>Skickat: {new Date(record.sent).toLocaleString()}</p>
                      {/* <p>
                        Från: {record.user.firstname} {record.user.lastname}
                      </p> */}
                      {/* <p>
                        Status:{" "}
                        {record.status
                          ? convertStatus(record.status)
                          : "Inväntar status"}
                      </p> */}
                    </li>
                  );
                })
            )
          ) : (
            <li>Inga mail hittades</li>
          )}
        </ul>
      </div>
      <div className="section">
        <h4>Information</h4>

        {selectedRecord ? (
          <div className="record-info">
            <div>
              <h5>Till:</h5>
              <span> {selectedRecord.to}</span>
            </div>
            <div>
              <h5>Från:</h5>
              <span>
                {" "}
                {selectedRecord.user.firstname} {selectedRecord.user.lastname} (
                {selectedRecord.from})
              </span>
            </div>
            <div>
              <h5>Skickat:</h5>
              <span> {new Date(selectedRecord.sent).toLocaleString()}</span>
            </div>
            <div>
              <h5>Status:</h5>
              <span> {convertStatus(selectedRecord.status)}</span>
            </div>
            <div>
              <h5>Företag:</h5>
              <span> {selectedRecord.company.name}</span>
            </div>
            <div>
              <h5>Meddelande:</h5>
              <span>
                {" "}
                {selectedRecord.message.replace(/<br\s*\/?>/gi, " ")}
              </span>
            </div>
            <div>
              <h5>Häfte:</h5>
              <span> {selectedRecord.booklet.title}</span>
            </div>
            {isReview && (
              <div>
                <h5>Kuponger:</h5>
                <ul>
                  {selectedRecord.review?.coupons.map((coupon, index) => {
                    return (
                      <li key={index}>
                        <p
                          style={{
                            marginBottom: "10px",
                            borderBottom:
                              "1px solid rgba(128, 128, 128, 0.319)",
                          }}
                        >
                          {coupon.discount} {coupon.smallTitle} <br />
                          {coupon.terms} <br />
                          {coupon.description}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        ) : (
          <h5>
            {isReview
              ? "Klicka på en granskning i listan för att se mer information"
              : "Klicka på ett meddelande i listan för att se mer information"}
          </h5>
        )}
      </div>
    </>
  );
}

function convertStatusToColor(statusString: string) {
  const statusColors: Record<string, string> = {
    Sent: "rgba(0, 255, 0, 0.150)",
    Pending: "rgba(255, 119, 0, 0.200)",
    Held: "rgba(255, 0, 0, 0.150)",
    HardFail: "rgba(255, 0, 0, 0.150)",
  };

  return statusColors[statusString] || "rgba(255, 0, 0, 0.150)";
}
