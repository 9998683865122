export default function convertStatus(statusString: string): string {
  const statusMap: Record<string, string> = {
    Sent: "Levererat",
    Pending: "Inväntar status",
    Held: "Stoppad, flera misslyckade mail till denna adress",
    HardFail: "Kunde inte leverera. Möjligen felaktig mailadress",
  };

  return statusMap[statusString] || statusString;
}
