import gql from 'graphql-tag';

// QUERIES

const getCompanyBookletReviewQuery = gql`
  query ($companyId: ObjectId!, $bookletId: ObjectId!) {
    bookletCompanyReview: bookletCompanyReview(companyId: $companyId, bookletId: $bookletId) {
      _id
      bookletId
      sentToEmails
      coupons {
        _id
      }
      comment
      approvedDate
      approvedByAdmin
      messageToCompany
      isActive
      sentDate
    }
  }
`;

const companyReviewByTokenQuery = gql`query BookletCompanyReviewByToken($token: String!) {
  bookletCompanyReviewByToken(token: $token) {
    _id
    bookletId
    company {
      _id
      name
      logoURL
      websiteURL
      typeId
      companyCategoryId
      phone
      visitAddress {
        streetAddress
        city
      }
    }
    coupons {
      _id
      discount 
      description
      smallTitle
      productLink
      terms
      imageURL
    }
    sentDate
    comment
    approvedDate
    isActive
  }
}`;

// MUTATIONS

const addBookletCompanyReviewMutation = gql`
  mutation($bookletId: ObjectId!, $companyId: ObjectId!, $couponIds: [ObjectId!]!, $shouldSendMail: Boolean, $sentToEmails: [String!], $messageToCompany: String) {
    createBookletCompanyReview(
      bookletId: $bookletId
      companyId: $companyId
      couponIds: $couponIds
      shouldSendMail: $shouldSendMail
      sentToEmails: $sentToEmails
      messageToCompany: $messageToCompany
    ) {
      _id
      sentToEmails
    }
  }

`;

const updateBookletCompanyReviewMutation = gql`
  mutation(
    $_id: ObjectId!
    $bookletId: ObjectId
    $token: String
    $sentToEmails: [String]
    $sentDate: DateTime
    $company: CompanyInput
    $coupons: [CouponInput]
    $comment: String
    $approvedDate: DateTime  
    $approvedByAdmin: String
    $messageToCompany: String
    $isActive: Boolean
    ) {
      updateBookletCompanyReview(
        _id: $_id,
        bookletId: $bookletId,
        token: $token,
        sentToEmails: $sentToEmails,
        sentDate: $sentDate,
        company: $company,
        coupons: $coupons,
        comment: $comment,
        approvedDate: $approvedDate
        approvedByAdmin: $approvedByAdmin
        messageToCompany: $messageToCompany
        isActive: $isActive
      ) {
        _id
      }
  }
`;
const updateBookletCompanyReviewWithTokenMutation = gql`
  mutation(
    $token: String!
    $comment: String
    $approvedDate: DateTime
    ) {
      updateBookletCompanyReviewWithToken(
        token: $token,
        comment: $comment,
        approvedDate: $approvedDate
      ) {
        _id
      }
  }
`;

export {
  getCompanyBookletReviewQuery,
  companyReviewByTokenQuery,
  addBookletCompanyReviewMutation,
  updateBookletCompanyReviewMutation,
  updateBookletCompanyReviewWithTokenMutation
}
