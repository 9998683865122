import React, { useEffect, useState, useRef } from 'react';
import { GrClose } from 'react-icons/gr';
import { FaCheck } from 'react-icons/fa';
import { ImEye } from 'react-icons/im';
import { FaPen } from 'react-icons/fa';
import { useMutation, useQuery } from '@apollo/client';
import { getAdminableBookletsQuery, updateCouponsInBookletMutation } from '../../queries/booklet';
import { addBookletCompanyReviewMutation, getCompanyBookletReviewQuery, updateBookletCompanyReviewMutation } from '../../queries/bookletCompanyReview';
import { usePreviewContext } from '../../context/PreviewContext';
import { MdSearch } from 'react-icons/md';
import { CgSpinner } from 'react-icons/cg';
import { useParams } from 'react-router';
import HistoryTab from './popUpTabs/HistoryTab';
import { getReviewEmailRecordQuery } from '../../queries/emailRecord';
import convertStatus from './utils/convertStatus';
import { getMyUserIdQuery as getMyUserNameQuery } from '../../queries/user';
import { Button } from '../../common/Form';


function BookletCouponsReviewPopup(props) {
  const { coupons, booklet, adminEmails, zoneId, isCompanyInView, companyId } = props;

  const [updateCouponsInBooklet] = useMutation(updateCouponsInBookletMutation);
  const [addReview, { loading: addReviewLoading }] = useMutation(addBookletCompanyReviewMutation);
  const [updateReview] = useMutation(updateBookletCompanyReviewMutation);

  const { data: reviewData, refetch: refetchReview, loading: isReviewLoading, startPolling: startReviewPolling, stopPolling: stopReviewPolling } = useQuery(getCompanyBookletReviewQuery, {
    variables: {
      companyId: coupons[0]?.companyId,
      bookletId: booklet?._id
    },
    skip: !isCompanyInView,
    fetchPolicy: 'network-only'
  });

  const { data: userData } = useQuery(getMyUserNameQuery);
  const userName = userData?.user?.firstname + ' ' + userData?.user?.lastname;

  const presetMessage = 'Tack och grattis till ert deltagande i vårt digitala rabatthäfte.\n\n' +
    'För att säkerställa att era kuponger blir korrekta ber vi er granska dem snarast. Det tar bara 1 min!\n\n' +
    'Om ni inte inkommer med ett svar inom 7 dagar antar vi att kupongerna är korrekta och publicerar dem i häftet.'

  const tabs = {
    REVIEW: "reviewTab",
    HISTORY: "historyTab"
  }

  const UNKNOWN_EMAIL_STATUS = 'Kunde inte hämta status';

  const [isInFocus, setIsInFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [checkedCouponIds, setCheckedCouponIds] = useState([]);
  const [messageToCompany, setMessageToCompany] = useState(presetMessage);
  const [couponsAwaitingReview, setCouponsAwaitingReview] = useState([]);
  const [couponsWithComment, setCouponsWithComment] = useState([]);
  const [couponsApproved, setCouponsApproved] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tabs.REVIEW);
  const [emailStatus, setEmailStatus] = useState('')

  const { zone } = useParams();

  const review = reviewData?.bookletCompanyReview;

  const { data: reviewEmailRecordData, refetch: refetchReviewEmailRecord } = useQuery(getReviewEmailRecordQuery, {
    variables: {
      reviewId: review?._id,
    },
    skip: !review?._id,
    fetchPolicy: 'network-only'
  })

  const reviewEmailRecord = reviewEmailRecordData?.emailRecord;

  useEffect(() => {
    if (review?.approvedDate) {
      setCouponsApproved(review.coupons.map(coupon => coupon._id))
    }
    else if (review?.comment) {
      setCouponsWithComment(review.coupons.map(coupon => coupon._id))
    }
    else if (review?.sentToEmails) {
      setCouponsAwaitingReview(review.coupons.map(coupon => coupon._id))
    }
    else {
      setCouponsApproved([]);
      setCouponsWithComment([]);
      setCouponsAwaitingReview([]);
    }
  }, [review]);

  /**
   * Refetches review if it doesn't have a approved date.
   * Retries every 10 seconds.
   * If the emailStatus get set to UNKOWN_EMAIL_STATUS we don't 
   * know that the email even got delivered so we should stop 
   * refetching review after that.
   */
  useEffect(() => {
    if (!review || review?.approvedDate) {
      stopReviewPolling();
      return;
    }

    if (emailStatus === UNKNOWN_EMAIL_STATUS) {
      stopReviewPolling();
    }

    if (reviewEmailRecord?.status === 'Sent' || reviewEmailRecord?.status === 'Pending') {
      startReviewPolling(10000)
    } else {
      stopReviewPolling();
    }

    return () => stopReviewPolling();
  }, [review, reviewEmailRecord?.status, emailStatus]);

  /**
   * Refetch email status
   * If postal webhook is down it will not update email status so 
   * we set it to have max attempts
   */
  const MAX_ATTEMPTS = 10;
  const intervalRef = useRef(null);
  const attemptCountRef = useRef(0);

  useEffect(() => {
    setEmailStatus('')
    attemptCountRef.current = 0;

    if (!review?._id || reviewEmailRecord?.status !== 'Pending') {
      setEmailStatus(reviewEmailRecord?.status)
      clearInterval(intervalRef.current)
      attemptCountRef.current = 0;
      return;
    }

    const timeout = setTimeout(() => {

      intervalRef.current = setInterval(() => {
        if (attemptCountRef.current >= MAX_ATTEMPTS) {
          clearInterval(intervalRef.current);
          setEmailStatus(UNKNOWN_EMAIL_STATUS)
          return;
        }
        refetchReviewEmailRecord();
        attemptCountRef.current += 1;
      }, 2000);
    }, 2000);

    return () => {
      clearTimeout(timeout);
      clearInterval(intervalRef.current)
    }

  }, [review?._id, reviewEmailRecord?.status])

  // functions 
  const handleMessageChange = (event) => {
    setMessageToCompany(event.target.value)
  }
  const handleOpen = () => {
    setIsOpen(true);
    setIsInFocus(false);
  }

  const handleCloseReviewPopup = () => {
    setIsOpen(false);
    setIsInFocus(false);
    setCheckedCouponIds([]);
    setMessageToCompany(presetMessage);
  }

  const handleCheckbox = (e) => {
    const isChecked = e.target.checked;
    const id = e.target.name;

    if (isChecked) {
      setCheckedCouponIds((prevChecked) => [...prevChecked, id]);
    } else {
      setCheckedCouponIds(checkedCouponIds.filter((couponId) => couponId !== id));

      if (checkedCouponIds.length === 1) {
        handleCloseReviewPopup();
      }
    }
  }

  const handleClickActivateBtn = () => {
    const previousCoupons = [...booklet?.couponIds];
    const couponsToAdd = [...checkedCouponIds.filter(coupon => !previousCoupons.includes(coupon))]
    const couponsMerged = previousCoupons.concat(couponsToAdd);

    updateCouponsInBooklet({
      variables: {
        _id: booklet._id,
        title: booklet.title,
        couponIds: couponsMerged
      },
      refetchQueries: [
        {
          query: getAdminableBookletsQuery, variables: {
            zoneId: zoneId
          }
        }
      ]
    }).catch(error => {
      console.error('Error updating booklet: ',)
    });
    handleCloseReviewPopup();
  }

  const handleClickDeactivateBtn = () => {
    const previousCoupons = [...booklet?.couponIds];
    const couponsToInclude = previousCoupons.filter(coupon => !checkedCouponIds.includes(coupon));

    updateCouponsInBooklet({
      variables: {
        _id: booklet._id,
        title: booklet.title,
        couponIds: couponsToInclude
      },
      refetchQueries: [
        {
          query: getAdminableBookletsQuery, variables: {
            zoneId: zoneId
          }
        }
      ]
    }).catch(error => {
      console.error('Error updating booklet: ',)
    });
    handleCloseReviewPopup();

  }

  const handleClickSendEmailReview = async () => {
    const formatedMsg = messageToCompany.replace(/\n\n/g, '<br><br>');

    await addReview({
      variables: {
        bookletId: booklet._id,
        companyId: coupons[0]?.companyId,
        couponIds: checkedCouponIds,
        shouldSendMail: true,
        sentToEmails: adminEmails,
        messageToCompany: formatedMsg
      }
    }).then(async () => {
      await refetchReview();
      setMessageToCompany(presetMessage);
    }).catch(error => {
      console.error('Error creating review:', error)
      alert('Någonting gick fel när review skulle skickas!');
    })
  }

  const handleClickApproveReview = async () => {
    const resolved = await addReview({
      variables: {
        bookletId: booklet._id,
        companyId: coupons[0]?.companyId,
        couponIds: checkedCouponIds,
      }
    }).catch(error => {
      console.error('Error creating review')
    });

    const id = resolved.data.createBookletCompanyReview._id;

    updateReview({
      variables: {
        _id: id,
        approvedDate: new Date(),
        approvedByAdmin: userName
      }
    }).catch(error => {
      console.error('Error updating review in handleClickApproveReview: ', error)
    }).then(() => {
      refetchReview();
    })
    handleCloseReviewPopup();
  }

  const handleClickCancelReview = async () => {

    const id = review?._id

    updateReview({
      variables: {
        _id: id,
        isActive: false
      },
      refetchQueries: [
        { query: getCompanyBookletReviewQuery, variables: { companyId: coupons[0]?.companyId, bookletId: booklet?._id } }
      ]
    }).catch(error => {
      console.error('Error updating review in handleClickCancelReview: ', error)
    })
  }

  return (
    <>
      {isOpen && <div className='pop-up-overlay'></div>}

      {/* Review popup container */}
      <div className={`pop-up-btn ${isInFocus ? 'hovered' : ''} ${isOpen ? 'open' : ''}`}
        onMouseEnter={() => setIsInFocus(true)}
        onMouseLeave={() => setIsInFocus(false)}
      >
        <PreviewButton booklet={booklet} companyId={coupons[0].companyId}></PreviewButton>
        {coupons.map((coupon, index) => (
          <div
            key={index}
            className={`pop-up-status-box ${booklet?.couponIds?.includes(coupon._id) ? 'included' : ''}`}
          >
            {(isReviewLoading || !reviewData) && isCompanyInView && <CgSpinner />}
            {couponsApproved.includes(coupon._id) && <FaCheck />}
            {couponsAwaitingReview.includes(coupon._id) && <ImEye />}
            {couponsWithComment.includes(coupon._id) && <FaPen />}
            <input type="checkbox"
              className={`pop-up-checkbox ${isInFocus ? 'hovered' : ''} ${isOpen ? 'open' : ''}`}
              name={coupon._id}
              checked={checkedCouponIds.includes(coupon._id)}
              onChange={(e) => handleCheckbox(e)}
              onClick={() => handleOpen()} />
          </div>
        ))}
      </div>

      {/* Review popup content */}
      {isOpen &&


        <div className='pop-up'>
          <div className='pop-up-tabs'>
            <div>
              <button className={`tab-button ${selectedTab === tabs.REVIEW ? 'active' : ''}`}
                onClick={() => setSelectedTab(tabs.REVIEW)}>Allmänt</button>
              <button className={`tab-button ${selectedTab === tabs.HISTORY ? 'active' : ''}`}
                onClick={() => setSelectedTab(tabs.HISTORY)}>Historik</button>
            </div>
            <div className='close'
              onClick={() => handleCloseReviewPopup()}
            ><GrClose /></div>
          </div>
          <div className='pop-up-content'>
            <div className='review-pop-up'>
              {selectedTab === tabs.REVIEW ? (
                <>
                  <div className='section'>
                    <h4>Kommentar från företag</h4>
                    <div className='comment-box'><p>{review?.comment}</p></div>
                  </div>
                  <div className='section'>
                    <h4>Ställ in manuellt</h4>
                    <button className="secondary" onClick={() => handleClickDeactivateBtn()}>Inaktivera</button>
                    <button className="secondary" onClick={() => handleClickActivateBtn()}>Aktivera</button>
                    <button className="secondary" onClick={() => handleClickCancelReview()}>Avbryt granskning</button>
                    <button className="secondary" disabled={couponsApproved.length >= 1 || couponsAwaitingReview.length >= 1 || couponsWithComment.length >= 1 ? true : false} onClick={() => handleClickApproveReview()}>Markera godkänd</button>
                  </div>
                  <div className='section'>
                    <h4>Begär granskning</h4>
                    {couponsApproved.length >= 1 || couponsAwaitingReview.length >= 1 || couponsWithComment.length >= 1 ? (
                      <>
                        <div style={{ color: 'red', maxWidth: '260px' }}>
                          {review?.approvedDate
                            ? 'Granskning godkänd! För att skicka en ny granskning eller godkänna kuponger direkt, tryck först "Avbryt granskning".'
                            : 'Granskning pågår! För att skicka en ny granskning eller godkänna kuponger direkt, tryck först "Avbryt granskning".'
                          }
                        </div>
                        <div className="info-row-container">
                          {review?.sentDate && (
                            <div className='info-row'>
                              <strong>Skickad: </strong>
                              <span>{new Date(review.sentDate).toLocaleDateString('sv-SE')}</span>
                            </div>
                          )}
                          {review?.approvedDate && (
                            <div className='info-row'>
                              <strong>Godkänd: </strong>
                              <span>{new Date(review.approvedDate).toLocaleDateString('sv-SE')}</span>
                              {review?.approvedByAdmin
                                ? <span> (av {review.approvedByAdmin})</span>
                                : <span> (av Annonsören)</span>
                              }
                            </div>
                          )}
                          <div className='info-row'>
                            <strong>Mailstatus: </strong>
                            <span>{' '}{emailStatus ? convertStatus(emailStatus) : 'Intväntar status'}</span>
                          </div>
                          {review?.messageToCompany && (
                            <div className='info-row' style={{ maxWidth: '320px' }}>
                              <strong>Meddelande som skickades:</strong>
                              <div className='sent-message' dangerouslySetInnerHTML={{ __html: review.messageToCompany }}></div>
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <textarea className="comment-box" name="" id="" cols="30" rows="10" value={messageToCompany} onChange={handleMessageChange}></textarea>
                        <button disabled className="primary">Skicka (SMS)</button>
                        <Button disabled={couponsApproved.length >= 1 || couponsAwaitingReview.length >= 1 || couponsWithComment.length >= 1 ? true : false}
                          className="primary"
                          loading={addReviewLoading}
                          onClick={() => handleClickSendEmailReview()}>Skicka email</Button>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <HistoryTab bookletId={booklet._id} zoneId={zone} companyId={companyId} isReview={true} />
              )}
            </div>
          </div>
        </div>
      }
    </>
  )
}

const PreviewButton = ({ booklet, companyId }) => {
  const { isPreviewOpen, companyToPreview, setCompanyToPreview } = usePreviewContext();

  const handleSetCompanyPreview = () => {
    setCompanyToPreview({
      companyId: companyId,
      bookletId: booklet._id,
    });
  };

  return (
    <td className="booklet-column preview">
      <div className='flex items-center justify-center'>
        <button
          className={`preview-button ${isPreviewOpen && (companyToPreview?.companyId === companyId?._id && companyToPreview?.bookletId === booklet?._id) ? 'active' : ''}`}
          onClick={handleSetCompanyPreview}
        >
          <MdSearch size={26} />
        </button>
      </div>
    </td>
  );
}

export default BookletCouponsReviewPopup;
