import gql from 'graphql-tag/lib';

const getCompanyEmailRecordsQuery = gql`
  query ($zoneId: ObjectId!) {
    emailRecords: companyEmailRecords(zoneId: $zoneId) {
      _id
      messageType
      title
      message
      to
      from
      attachment
      sent
      status
      user {
      firstname
      lastname
      }
      booklet {
        title
      }
      company{
        name
      }
    }
  }
`;

const getReviewEmailRecordsQuery = gql`
  query ($zoneId: ObjectId!, $companyId: ObjectId!, $bookletId: ObjectId!) {
    emailRecords: couponsReviewEmailRecords(zoneId: $zoneId, companyId: $companyId, bookletId: $bookletId) {
      _id
      messageType
      title
      message
      to
      from
      attachment
      sent
      status
      user {
      firstname
      lastname
      }
      booklet {
        title
      }
      company {
        name
      }
      review{
        coupons {
          terms
          discount
          smallTitle
          description
        }
      }
    }
  }
`;

const getReviewEmailRecordQuery = gql`
  query ($reviewId: ObjectId!) {
    emailRecord: couponsReviewEmailRecord(reviewId: $reviewId) {
      status
    }
  }
`;



export {
  getCompanyEmailRecordsQuery,
  getReviewEmailRecordsQuery,
  getReviewEmailRecordQuery
}

