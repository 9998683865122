import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { addAssociationMutation, updateAssociationMutation, getAssociationsInZoneQuery } from '../../queries/queries';
import { Form, Group, Field } from '../../common/index';
import { inviteAssociationAdmin } from '../../queries/invitations';
import { useMutation } from '@apollo/client';
function AssociationsForm(props) {
  const { content, onComplete } = props;
  const [sContent, setSContent] = useState(content);

  const [invitationEmails, setInvitationEmails] = useState([]);
  const [invitationAssociationAdmin] = useMutation(inviteAssociationAdmin, {
    onCompleted: (res) => {
      console.log('Inbjudan skickad till föreningsansvarig', res);
    },
    onError: (err) => console.log(err)
  });

  useEffect(() => {
    setSContent({
      ...content,
      bStreetAddress: content.billingAddress && content.billingAddress.streetAddress,
      bZipCode: content.billingAddress && content.billingAddress.zipCode,
      bCity: content.billingAddress && content.billingAddress.city,
      bState: content.billingAddress && content.billingAddress.state,
      // Set default to current domain (if new association)
      showInShopListOnDomains: content?._id
        ? content.showInShopListOnDomains
        : [window.location.hostname]
    });
  }, [content]);

  const ss = (c) => setSContent({ ...sContent, ...c });

  return (
    <tr key={content ? content._id : 'new'} className="open">
      <td colSpan="100">
        <Form sContent={sContent} parent="zoneId" get={getAssociationsInZoneQuery} add={addAssociationMutation} update={updateAssociationMutation} onComplete={onComplete}
          invitation={invitationAssociationAdmin}
          invitationAdminEmails={invitationEmails}
        >
          <Group title="Allmänt">
            <Field type="text" req name="name" title="Förening" value={sContent.name} onChange={ss} />
            <Field type="textarea" name="note" title="Anteckning" value={sContent.note} onChange={ss} />
            <Field type="image" name="logoURL" title="Logotyp (max 1MB)" value={sContent.logoURL} onChange={ss} />
            <Field
              type="image"
              name="shopShareImageURL"
              title="Delningsbild, shop (max 1MB)"
              value={sContent.shopShareImageURL}
              onChange={ss}
            />
          </Group>
          <Group title="Kontaktuppgifter">
            <Field type="phone" name="phone" title="Telefon" value={sContent.phone} onChange={ss} />
            <Field type="text" name="bStreetAddress" title="Gatuadress" value={sContent.bStreetAddress} onChange={ss} />
            <Field type="text" name="bZipCode" title="Postnummer" value={sContent.bZipCode} onChange={ss} />
            <Field type="text" name="bCity" title="Stad" value={sContent.bCity} onChange={ss} />
            <Field type="text" name="bState" title="Län" value={sContent.bState} onChange={ss} />
          </Group>
          <Group title="Inställningar">
            <Field type="admins" name="invitationEmails" title="Bjud in för att bli Administratörer" value={invitationEmails} onChange={(value) => setInvitationEmails(value)} />
            <Field type="admins" name="adminEmails" title="Administratörer" value={sContent.adminEmails} onChange={ss} />
            <Field
              type="admins"
              name="showInShopListOnDomains"
              title="Domäner där shopen visas i listan"
              placeholder='Ange en domän och tryck enter'
              value={sContent.showInShopListOnDomains}
              onChange={ss}
              x2
            />
          </Group>
        </Form>
      </td>
    </tr>
  );
}

AssociationsForm.propTypes = {
  content: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default AssociationsForm;
